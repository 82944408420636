import request from '@/plugin/axios'
import baseApi from './base'

export function GetJobListApi(data) {
  return request({
    url: `${baseApi.napi}/job`,
    method: 'get',
    params: data
  })
}

export function GetJobDetailApi(id) {
  return request({
    url: `${baseApi.napi}/job/${id}`,
    method: 'get'
  })
}

export function DelJobRowApi(data) {
  const { id } = data
  delete data.id
  return request({
    url: `${baseApi.napi}/job/${id}`,
    method: 'delete',
    params: data
  })
}

export function EditJobApi(data, id) {
  return request({
    url: `${baseApi.napi}/job/${id}`,
    method: 'patch',
    data
  })
}

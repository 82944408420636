<template>
  <div>
    <!-- <el-upload
      id="tinymce-upload"
      class="uploader"
      action="#"
      name="FileData"
      :show-file-list="false"
      :on-success="handleAccountSuccess"
      :before-upload="beforeAccountUpload"
      style="display: none;"
    ></el-upload> -->
    <editor v-model="content" :init="EditorInitOpts" />
  </div>
</template>

<script>
import _ from 'lodash'
import baseApi from '@/api/base'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/table'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/paste'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'

export default {
  components: {
    Editor
  },
  props: {
    option: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      basePicApi: baseApi,
      content: '',
      EditorInitConfig: {
        language_url: './js/langs/zh_CN.js',
        fontsize_formats: '12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px',
        language: 'zh_CN',
        skin_url: './js/skins/ui/oxide',
        height: 570,
        plugins: 'table lists paste preview fullscreen link media',
        media_live_embeds: true,
        media_alt_source: false,
        paste_data_images: true,
        forced_root_block: 'p',
        extended_valid_elements: 'figcaption',
        toolbar:
          'fontselect fontsizeselect forecolor backcolor bold italic underline strikethrough alignleft aligncenter alignright alignjustify link quicklink h2 h3 blockquote table preview fullscreen media imageUpload',
        audio_template_callback: function(data) {
          return '<audio controls>' + '\n<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + '</audio>'
        },
        video_template_callback: function(data) {
          return '<video width="100%" height="300px"' + (data.poster ? ' poster="' + data.poster + '"' : '') + ' controls="controls">\n' + '<source src="' + data.source1 + '"' + (data.source1mime ? ' type="' + data.source1mime + '"' : '') + ' />\n' + (data.source2 ? '<source src="' + data.source2 + '"' + (data.source2mime ? ' type="' + data.source2mime + '"' : '') + ' />\n' : '') + '</video>'
        },
        setup: (editor) => {
          editor.ui.registry.addButton('imageUpload', {
            tooltip: 'Insert image',
            icon: 'image',
            onAction: () => {
              this.$emit('addPic', true)
            }
          })
          editor.ui.registry.addButton('link', {
            tooltip: 'Insert link',
            icon: 'link',
            onAction: () => {
              this.dialogTableVisible = true
            }
          })
        }
      }
    }
  },
  computed: {
    EditorInitOpts() {
      return _.defaultsDeep({}, this.option, this.EditorInitConfig)
    }
  },
  watch: {
    content(newVal) {
      this.$emit('change', newVal)
    }
  },
  methods: {
    init(content) {
      this.content = content
    },
    insertHtml(html) {
      tinymce.execCommand('mceInsertContent', false, html)
    },

    insert(item) {
      tinymce.activeEditor.selection.setContent(`<article role="news">
        <label data-id="${item.id}"><h1>${item.title}</h1></label>
        ${item.summary.length > 130 ? item.summary.substr(0, 130) + '...' : item.summary}
     </article>`)
    },

    // 插入图片至编辑器
    // element upload
    // handleAccountSuccess () {},
    // beforeAccountUpload (file) {
    //   blobToBase64(file).then(res => {
    //     tinymce.execCommand('mceInsertContent', false, `<figure><img src=${res}><figcaption><i>编辑图说</i></figcaption></figure>`)
    //   })
    // },
    insertPic(arr) {
      let str = ''
      for (const index in arr) {
        const path = this.basePicApi.napi + '/file/thumb/' + arr[index].filename + '.350x'
        str = str + `<figure><img src=${path}><figcaption class="figcaption"></figcaption></figure>`
      }
      // tinymce.execCommand('mceInsertContent', false, `${str}`)
      tinymce.activeEditor.selection.setContent(`${str}`)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

import request from '@/plugin/axios'
import baseApi from './base'

export function GetTagsApi() {
  return Promise.resolve({})
}

export function GetPortListApi(data) {
  return Promise.resolve({})
}

export function GetAirportListApi(data) {
  return Promise.resolve({})
}

export function GetCarrierListApi(data) {
  return Promise.resolve({})
}

export function GetAllPortListApi(data) {
  return Promise.resolve({})
}

export function GetPortDetailApi(data) {
  return Promise.resolve({})
}

export function GetInsertContentApi(data) {
  return Promise.resolve({})
}

export function PictureUploadApi(data) {
  return request({
    url: `${baseApi.napi}/file/image`,
    method: 'post',
    data
  })
}

export function PicturePatchApi(data) {
  return request({
    url: `${baseApi.napi}/gallery/image/${data.id}`,
    method: 'patch',
    data
  })
}

export function PictureListApi(data) {
  return request({
    url: `${baseApi.napi}/gallery/image`,
    method: 'get',
    params: data
  })
}

export function PictureDeleteApi(id) {
  return request({
    url: `${baseApi.napi}/gallery/image/${id}`,
    method: 'delete'
  })
}

import {
  GetGuestbookListApi,
  GetGuestbookDetailApi,
  EditGuestbookApi
} from '@api/guestbook'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 获取留言板列表
    guestbookList({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        GetGuestbookListApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 留言板详情
    guestbookDetail({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        GetGuestbookDetailApi(id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 编辑留言板
    guestbookEdit({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        EditGuestbookApi(data, data.id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

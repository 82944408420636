import {
  GetJobListApi,
  GetJobDetailApi,
  DelJobRowApi,
  EditJobApi
} from '@api/job'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 获取岗位列表
    jobList({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        GetJobListApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 岗位详情
    jobDetail({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        GetJobDetailApi(id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 删除岗位集合
    jobDel({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        DelJobRowApi({ id }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 编辑岗位
    jobEdit({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        EditJobApi(data, data.id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

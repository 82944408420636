import {
  GetCommentListApi
} from '@api/news.comment'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 获取资讯列表
    getCommentList({ dispatch }, {
      keyword = null,
      limit = 10,
      page = null,
      otherData = {}
    } = {}) {
      return new Promise((resolve, reject) => {
        GetCommentListApi({
          keyword,
          limit,
          page,
          ...otherData
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

import request from '@/plugin/axios'
import baseApi from './base'

export function GetFeedbackListApi(data) {
  return request({
    url: `${baseApi.fapi}/feedback`,
    method: 'get',
    params: data
  })
}

import _ from 'lodash'

import {
  GetInsertContentApi,
  GetAllPortListApi,
  GetCarrierListApi,
  GetAirportListApi,
  PictureUploadApi,
  PicturePatchApi,
  PictureListApi,
  PictureDeleteApi,
  GetPortListApi,
  GetTagsApi
} from '@api/commons'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 文章标签获取
    getTags({ dispatch }) {
      return new Promise((resolve, reject) => {
        GetTagsApi().then(async res => {
          res.tagsStr = []
          _.times(res.list.length, (i) => {
            res.tagsStr.push(res.list[i].name)
          })
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 港口列表
    getPortList({ dispatch }, {
      keyword = null,
      type = null
    } = {}) {
      return new Promise((resolve, reject) => {
        GetPortListApi({
          keyword,
          type
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 港口百科
    getAllPortList({ dispatch }, {
      keyword = null,
      type = null
    } = {}) {
      return new Promise((resolve, reject) => {
        GetAllPortListApi({
          keyword,
          type
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 机场百科
    getAirportList({ dispatch }, {
      keyword = null,
      type = null
    } = {}) {
      return new Promise((resolve, reject) => {
        GetAirportListApi({
          keyword,
          type
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 船司百科
    getCarrierList({ dispatch }, {
      keyword = null,
      type = null
    } = {}) {
      return new Promise((resolve, reject) => {
        GetCarrierListApi({
          keyword,
          type
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 专题插入文章列表
    getInsertContent({ dispatch }, {
      tag_name = null,
      page = 0,
      keyword = null,
      limit = 10
    } = {}) {
      return new Promise((resolve, reject) => {
        GetInsertContentApi({
          tag_name,
          page,
          keyword,
          limit
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 图片上传
    pictureUpload({ dispatch }, {
      file = null
    } = {}) {
      return new Promise((resolve, reject) => {
        PictureUploadApi({
          file
        }).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 图片上传
    picturePatch({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        PicturePatchApi(data).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 图片列表
    pictureList({ dispatch }, data = {}) {
      return new Promise((resolve, reject) => {
        PictureListApi(data).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 图片删除
    pictureDelete({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        PictureDeleteApi(id).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

import request from '@/plugin/axios'
import baseApi from './base'

export function AccountLogin(data) {
  return request({
    url: `${baseApi.napi}/token`,
    method: 'post',
    data
  })
}

export function AccountLogout(token) {
  return request({
    url: `${baseApi.napi}/token/${token}`,
    method: 'delete'
  })
}

import { pickBy, isNil } from 'lodash'
import store from '@/store'
import axios from 'axios'
import { Message } from 'element-ui'
import util from '@/libs/util'
import qs from 'qs'

// 创建一个错误
function errorCreate(msg) {
  const error = new Error(msg)
  errorLog(error)
  throw error
}

// 记录和显示错误
function errorLog(error) {
  // 添加到日志
  store.dispatch('d2admin/log/push', {
    message: '数据请求异常',
    type: 'danger',
    meta: {
      error
    }
  })
  // 打印到控制台
  if (process.env.NODE_ENV === 'development') {
    util.log.danger('>>>>>> Error >>>>>>')
    console.log(error)
  }
  // 显示提示
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })

  store.dispatch('d2admin/account/logout')
}

// 创建一个 axios 实例
const service = axios.create({
  timeout: 0 // 请求超时时间
})

service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    // const token = util.cookies.get('token')
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    // config.headers['X-Token'] = token

    const token = util.cookies.get('token')
    if (config.data) {
      config.data = qs.stringify({
        ...qs.parse(config.data, { strictNullHandling: true }),
        token: token || '',
        auth_key: token || ''
      }, { skipNulls: true })
    } else {
      if (!config.params) {
        config.params = {}
      } else {
        config.params = pickBy(config.params, v => !isNil(v))
      }
      config.params['token'] = token
      config.params['auth_key'] = token
    }
    return config
  },
  error => {
    // 发送失败
    console.log(error)
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    let code
    if (dataAxios.code) {
      code = dataAxios.code
    } else if (dataAxios.code === '') {
      code = ''
    } else if (dataAxios.code === 0) {
      code = 0
    } else {
      code = dataAxios.error_code
    }
    // 根据 code 进行判断
    if (code === undefined) {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios
    } else {
      const errorCode = {
        '20000': '请登录',
        '20001': '用户名或密码不能为空',
        '20002': '用户名或密码错误',
        '20010': '上传文件无效',
        '20020': '图片无法本地化',
        '21000': '验证码无效',
        '21001': '验证码错误',
        '30000': '反馈内容不能为空'
      }
      if (dataAxios.status === 0 || (dataAxios.data !== undefined && !dataAxios.data && !dataAxios.result)) {
        Message({
          message: dataAxios.message || errorCode[code] || '出错了',
          type: 'error',
          duration: 5 * 1000
        })
      }
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case '1000':
          // 当前未登录(当前操作必须登录才能进行)
          errorCreate(`${dataAxios.message}`)
          break
        case '3000':
          // 用户不存在
          errorCreate(`${dataAxios.message}`)
          break
        case '3001':
          // 用户被删除
          errorCreate(`${dataAxios.message}`)
          break
        case '3002':
          // 用户登录失败
          errorCreate(`${dataAxios.message}`)
          break
        default:
          return dataAxios.data || dataAxios.result
      }
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    errorLog(error)
    return Promise.reject(error)
  }
)

export default service

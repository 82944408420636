import request from '@/plugin/axios'
import baseApi from './base'

export function GetTopicsListApi(data) {
  return request({
    url: `${baseApi.napi}/topic`,
    method: 'get',
    params: data
  })
}

export function GetTopicsDetailApi(id) {
  return request({
    url: `${baseApi.napi}/topic/${id.id}`,
    method: 'get'
  })
}

export function SetTopicsDetailApi(data) {
  const id = data.id || 0
  return request({
    url: `${baseApi.napi}/topic/${id}`,
    method: 'patch',
    data
  })
}

export function EditTopicsRankAndHideApi(data) {
  return Promise.resolve({})
}

export function DelTopicsApi(data) {
  const { id } = data
  delete data.id
  return request({
    url: `${baseApi.napi}/topic/${id}`,
    method: 'delete',
    params: data
  })
}

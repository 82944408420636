import {
  UpdateXzhRemainApi,
  GetNewsDetailApi,
  XzhRemainListApi,
  GetNewsListApi,
  DelNewsRowApi,
  EditNewsApi,
  AddNewsApi
} from '@api/news.news'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 获取资讯列表
    newsList({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        GetNewsListApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 删除资讯集合
    newsDel({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        DelNewsRowApi({ id }).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 编辑资讯
    newsEdit({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        EditNewsApi(data, data.id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 添加资讯
    newsAdd({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        AddNewsApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 资讯详情
    newsDetail({ dispatch }, id) {
      return new Promise((resolve, reject) => {
        GetNewsDetailApi(id).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 获取熊掌号资讯列表
    getXzhRemain({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        XzhRemainListApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    // 同步至熊掌号
    updateXzhRemain({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        UpdateXzhRemainApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

import _ from 'lodash'

import {
  EditTopicsRankAndHideApi,
  SetTopicsDetailApi,
  GetTopicsDetailApi,
  GetTopicsListApi,
  DelTopicsApi
} from '@api/news.topics'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    getTopicsList({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        GetTopicsListApi(data).then(async res => {
          _.times(res.rows.length, (i) => {
            res.rows[i].is_visible = !!res.rows[i].is_visible
          })
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    getTopicsDetail({ dispatch }, {
      id = null
    } = {}) {
      return new Promise((resolve, reject) => {
        GetTopicsDetailApi({
          id
        }).then(async res => {
          // res.tags_topic = res.tags_topic.split(',')
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    setTopicsDetail({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        SetTopicsDetailApi(data).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    editTopicsRankAndHide({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        EditTopicsRankAndHideApi(data).then(async res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },
    delTopics({ dispatch }, {
      id = null
    } = {}) {
      return new Promise((resolve, reject) => {
        DelTopicsApi({
          id
        }).then(async res => {
          console.log(res)
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

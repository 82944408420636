import {
  GetFeedbackListApi
} from '@api/feedback'

export default {
  namespaced: true,
  state: {
    // 全屏激活
    active: false
  },
  actions: {
    // 获取反馈列表
    feedbackList({ dispatch }, data) {
      return new Promise((resolve, reject) => {
        GetFeedbackListApi(data).then(res => {
          resolve(res)
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    }
  }
}

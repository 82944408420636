import request from '@/plugin/axios'
import baseApi from './base'

export function GetNewsListApi(data) {
  return request({
    url: `${baseApi.napi}/news`,
    method: 'get',
    params: data
  })
}

export function DelNewsRowApi(data) {
  const { id } = data
  delete data.id
  return request({
    url: `${baseApi.napi}/news/${id}`,
    method: 'delete',
    params: data
  })
}

export function EditNewsApi(data, id) {
  return request({
    url: `${baseApi.napi}/news/${id}`,
    method: 'patch',
    data
  })
}

export function AddNewsApi(data) {
  return request({
    url: `${baseApi.napi}/news/0`,
    method: 'patch',
    data
  })
}

export function GetNewsDetailApi(id) {
  return request({
    url: `${baseApi.napi}/news/${id}`,
    method: 'get'
  })
}

export function XzhRemainListApi(data) {
  return Promise.resolve({})
}

export function UpdateXzhRemainApi(data) {
  return Promise.resolve({})
}

import request from '@/plugin/axios'
import baseApi from './base'

export function GetGuestbookListApi(data) {
  return request({
    url: `${baseApi.napi}/guestbook`,
    method: 'get',
    params: data
  })
}

export function GetGuestbookDetailApi(id = 0) {
  return request({
    url: `${baseApi.napi}/guestbook/${id}`,
    method: 'get'
  })
}

export function EditGuestbookApi(data, id) {
  return request({
    url: `${baseApi.napi}/guestbook/${id}`,
    method: 'patch',
    data
  })
}
